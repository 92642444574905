<template lang="html" src="./pageSupplierAdd.template.vue"></template>

<style lang="scss" src="./pageSupplierAdd.scss"></style>

<script>
import { ApiErrorParser, FormAddress } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSupplierAdd.i18n');

export default {
  name: 'PageSupplierAdd',
  components: { FormAddress },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      formattedTechnologies: {},
      keyComponent: 0,
      supplierAddress: new this.$BcmModel.Address(),
      supplierEmail: null,
      supplierTechnologies: [],
      technologies: null,
      isValidVAT: false,
    };
  },
  created() {
    this.getAllTechnologiesAndMaterials();
  },
  methods: {
    getAllTechnologiesAndMaterials() {
      this.$apiInstance.getAllTechnologiesAndMaterialsCompatibilities().then(data => {
        const technologies = {};
        for(const technologyObj of data) {
          const materials = [];
          for(const materialObj of technologyObj.materials) {
            materials.push(materialObj.material);
          }
          technologies[technologyObj.technology] = materials;;
        }
        this.technologies = technologies;
      });
    },
    addTechnology(technology) {
      if (!this.supplierTechnologies.includes(technology)) {
        this.supplierTechnologies.push(technology);
      } else {
        const index = this.supplierTechnologies.indexOf(technology);
        if (index > -1) {
          this.supplierTechnologies.splice(index, 1);
        }
      }
    },
    /**
     * ADD SUPPLIER
     */
    submitForm() {
      this.formattedTechnologies = {};
      for (let i = 0; i < this.supplierTechnologies.length; i++) {
        this.formattedTechnologies[this.supplierTechnologies[i]] = [];
        for (
          let j = 0;
          j < this.$refs[this.supplierTechnologies[i]].length;
          j++
        ) {
          if (this.$refs[this.supplierTechnologies[i]][j].checked === true) {
            this.formattedTechnologies[this.supplierTechnologies[i]].push(
              this.$refs[this.supplierTechnologies[i]][j].value
            );
          }
        }
      }
      if (this.$refs.newSupplierAddress.validate()) {
        const addSupplierBody = new this.$BcmModel.AddSupplierBody(
          this.supplierAddress.company,
          this.supplierAddress,
          this.formattedTechnologies
        );
        this.$apiInstance.addSupplier(addSupplierBody).then(
          () => {
            this.$router.push({name: 'Suppliers'});
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS',this.$t('SupplierSuccessfullyAdded'));
          },
          (error) => {
            /**
             * ERROR ADD SUPPLIER
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
      }
    },
    forceRerender() {
      this.keyComponent += 1;
    },
    updateSupplierAddress(address) {
      this.supplierAddress = address;
    },
    async validateVAT(countryCode, vat) {
      this.isValidVAT = await this.$apiInstance.validateEuropeanVAT(countryCode, vat)
        .then((data) => {
          return true;
        }, (error) => {
          return false;
        });
    }
  },
};
</script>
