var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-supplierAdd"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('AddSupplier')))])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-container', {
    staticClass: "row mb-5 ma-0 pa-0"
  }, [_c('v-card', {
    staticClass: "col-lg col-12 mr-3"
  }, [_c('v-card-title', [_c('h5', [_vm._v(_vm._s(_vm.$t('Address')))])]), _c('v-card-text', [_c('formAddress', {
    ref: "newSupplierAddress",
    attrs: {
      "address": _vm.supplierAddress,
      "restricted-country-list": [],
      "is-valid-v-a-t": _vm.isValidVAT,
      "required-company": true,
      "required-zip-code": true,
      "required-phone-number": true,
      "required-v-a-tnumber": true,
      "allow-to-set-not-applicable-v-a-t": true
    },
    on: {
      "updated": _vm.updateSupplierAddress,
      "validateVAT": _vm.validateVAT
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "col-lg col-12 ml-3"
  }, [_c('v-card-title', [_c('h5', [_vm._v(_vm._s(_vm.$t('Technologies')))])]), _c('v-card-text', {
    key: _vm.keyComponent
  }, _vm._l(_vm.technologies, function (materials, technology, index) {
    return _c('div', {
      key: index,
      staticClass: "d-md-flex pa-3 ma-0"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('v-switch', {
      staticClass: "mr-4",
      attrs: {
        "inset": "",
        "color": "success",
        "label": _vm.$t(technology)
      },
      on: {
        "change": function ($event) {
          return _vm.addTechnology(technology);
        }
      }
    })], 1), _vm.supplierTechnologies.includes(technology) ? _c('div', _vm._l(materials, function (material, index) {
      return _c('div', {
        key: index
      }, [_c('input', {
        ref: technology,
        refInFor: true,
        staticClass: "mr-2 mb-2",
        attrs: {
          "type": "checkbox",
          "id": technology + material,
          "name": "scales",
          "checked": ""
        },
        domProps: {
          "value": material
        }
      }), _c('label', {
        attrs: {
          "for": technology + material
        }
      }, [_vm._v(_vm._s(_vm.$t(material)))])]);
    }), 0) : _vm._e()]);
  }), 0)], 1)], 1), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "to": {
        name: 'Suppliers'
      },
      "color": "error",
      "title": _vm.$t('Cancel')
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "title": _vm.$t('Submit')
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }